import React from "react";
import Head from "../components/Single/Head";
import Layout from "../layout/Layout";

function AboutScreen() {
  const project = {
    title: "About Us",
    image: "/images/main.jpg",
  };
  const Data = [
    {
      img: "2",
      title: "Organized functioning of industry.",
      subTitle:
        "Technicians, artists and movie makers, and other personnel in various film industries would be connected through the Filmcity platform. This helps in interaction and information sharing directly among these members. Thus, making no chance of unwanted utilization and right remuneration for all, in all regions.",
    },
    {
      img: "3",
      title: "Blockchain Visibility and Equity",
      subTitle:
        "This opens an opportunity for filmmakers and independent filmmakers to make their work come to life and see unexpected success and visibility through our platform.",
    },
  ];
  return (
    <Layout header={false}>
      <div className="min-h-screen container mx-auto px-2 py-2">
        <Head project={project} />
        <div className="flex-colo">
          <div className="xl:w-5/6 flex-colo py-12 gap-20">
            <div>
              <p className="my-6 leading-7">
                This project aims to digitally decentralize the power of
                distribution houses globally, across various industries. We are
                designing a wide spectrum of opportunities, offers and organized
                networking between filmmakers, technicians, enthusiasts, artists
                and more.
              </p>
              <div className="xl:grid grid-cols-7 gap-12">
                <div className="imageHeight col-span-3">
                  <img
                    src="/images/about.png"
                    alt="img"
                    className="h-full rounded w-full object-cover"
                  />
                </div>
                <div
                  className="col-span-4 mt-8"
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-delay="10"
                  data-aos-duration="1000"
                >
                  <h2 className="text-xl font-bold text-text">
                    Explore and connect the world of motion picture
                    entertainment
                  </h2>
                  <p className="mt-6 text-sm mb-12 leading-7">
                    This would be a one-stop solution for all filmmaking/ TV
                    content needs, from scripting to the final broadcast and
                    distribution. Millions of viewers would be turning up to
                    watch the content produced by us. Axyz Metafilmcity would be
                    a major choice for all, giving it a long run in the industry
                    as the one that can deal with multiple utilities in the
                    Global Film and Television industries.
                  </p>
                  <h2 className="text-xl font-bold text-text">
                    Find Top Industry talents.
                  </h2>
                  <p className="mt-6 mb-12 text-sm leading-7">
                    Axyz Meta Filmcity would be charting out smart contracts,
                    that can easily wipe off the middlemen in many areas of
                    content creation. It ensures complete authority and benefits
                    for content creators, with proper control of their
                    royalties, rewards, rights, etc.The Universal Film Studio is
                    yet another finely designed feature of this project, to
                    ensure complete production facilities in one space. Once a
                    story arrives at Universal Film Studio, it would be scripted
                    and executed through proper pre-production, production, and
                    post-production stages.
                  </p>
                </div>
              </div>
            </div>
            {/* about 2 */}
            <div className="grid lg:grid-cols-2 gap-12 mt-12">
              {Data.map((item, index) => (
                <div key={index}>
                  <img
                    src={`/images/about${item.img}.png`}
                    alt="img"
                    className="h-72 w-full object-cover rounded-md"
                  />
                  <h2 className="text-xl font-bold my-6">{item.title}</h2>
                  <p className="text-sm leading-7">{item.subTitle}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutScreen;
