import React, { useEffect, useState } from "react";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import { AiOutlineCaretUp } from "react-icons/ai";

const Layout = ({ children, header }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setActive(true);
      } else {
        setActive(false);
      }
    });
  }, [active]);

  return (
    <div className="relative">
      {active && (
        <button
          onClick={() => window.scroll(0, 0)}
          className="fixed border-2 flex-colo border-subMain z-50 bottom-10 right-10 w-14 h-14 bg-deepGray rounded-full shadow-2xl text-white text-xl"
        >
          <AiOutlineCaretUp />
        </button>
      )}

      <Navbar header={header} />
      <div className="bg-main">
        {children}
        <Footer />
      </div>
      {/* <MobileFooter /> */}
    </div>
  );
};

export default Layout;
