import React from "react";
import Titles from "./Titles";

function HowItWork({ project }) {
  return (
    <div className="w-full my-20">
      <Titles
        title="How To Participate"
        subTitle="How it works"
        Bg={true}
        link={project.link}
        button={true}
        subt={true}
        newTab={true}
        buttonText="View Project"
      />
      <div className="grid  sm:grid-cols-2 xl:grid-cols-3 xl:gap-16 gap-4 mt-12">
        {project.howItWork.map((h, i) => (
          <div key={i} className="rounded-md p-6 transitions hover:bg-deepGray">
            <img src={h.image} alt="1" className="w-16 h-16 object-contain" />
            <p className="mt-6 leading-6 font-semibold text-sm text-text">
              {h.dec}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HowItWork;
