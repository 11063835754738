import React from "react";
import Layout from "../layout/Layout";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import SingleTitle from "../components/Single/SingleTitle";

function FilmTokenPage() {
  return (
    <Layout header={false}>
      <div className="min-h-screen container mx-auto px-2 py-6 flex-colo">
        <div className=" lg:p-20 sm:p-10 py-10 px-6 rounded-xl bg-deepGray">
          <SingleTitle title="$Film Token" />

          <div className="grid xl:grid-cols-2 mt-12 gap-20">
            <div className="flex-col justify-start items-start text-text flex gap-6">
              <p>
                Being built on the secure Ethereum Network, FILMY is ERC 20
                utility token with multiple functions. Filmy is designed and
                developed to ensure all members of the Global Film and
                Television community benefit from blockchain and cryptocurrency
                technologies. This token can be made use of in everyday
                transactions, with a long-term vision.
              </p>
              <h4 className="font-medium text-xl text-white">FILMY- ERC 20</h4>
              <p>
                This Filmcity Utility coin would be released on the polygon
                network. Created using the ERC 20 standard and deployed into the
                Ethereum blockchain, acting as a currency for Axyz Meta
                Filmcity.
              </p>
              <Link
                to="/buytoken"
                className={`flex-rows bg-subMain text-white px-4 md:px-10 hover:scale-105 transitions py-3  gap-4 text-sm rounded-md`}
              >
                Buy Token
                <BiRightArrowAlt className="w-6 h-6" />
              </Link>
            </div>
            <div
              className="lg:mt-4 h-96"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              <img
                className="w-full h-full object-contain"
                src="/images/home/film.png"
                alt="film token"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FilmTokenPage;
