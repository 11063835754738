import React from "react";
import { Link } from "react-router-dom";
import { HomeProjectsData } from "../../Data/ProjectData";

function HomeProjects() {
  return (
    <div className="my-12 flex-colo">
      <div className="grid w-full xl:grid-cols-3 md:grid-cols-2 gap-10">
        {HomeProjectsData.map((project, index) => (
          <Link
            to={project?.link}
            key={index}
            className="p-6 rounded-lg bg-deepGray"
          >
            <img
              src={project?.image}
              alt={project?.title}
              className="w-full h-64 object-cover rounded"
            />
            <h3 className="text-md text-text font-semibold trancuted line-clamp-1 text-center mt-6">
              {project?.title}
            </h3>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default HomeProjects;
