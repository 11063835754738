import React from "react";
import Project from "../components/Project";
import { ProjectData } from "../Data/ProjectData";
import Layout from "../layout/Layout";
import { CgSpinner } from "react-icons/cg";

function ProjectsScreen() {
  return (
    <Layout header={false}>
      <div className="min-h-screen container mx-auto px-2">
        <div className="flex-colo">
          <div className="lg:w-5/6 w-full flex-colo md:pb-12 pt-24">
            <p className="uppercase tracking-widest text-sm text-subMain font-semibold">
              EXCLUSIVE
            </p>
            <h2 className="md:text-4xl text-xl text-white font-bold my-3">
              Multi-chain IGOs
            </h2>
            <hr className="w-12 border-2 border-subMain" />
            <p className="my-6 md:text-base text-sm text-center font-semibold leading-7 md:w-3/4 xl:w-2/4 text-text">
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim.quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in
              volupt exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat
            </p>
            <div className="grid md:grid-cols-2 xl:grid-cols-3 my-16 gap-12 items-center">
              {ProjectData.map((item, i) => (
                <Project item={item} key={i} />
              ))}
            </div>
            <button className="bg-white text-xs hover:scale-105 transitions text-subMain font-semibold py-3 px-4 flex-rows gap-4 rounded mb-10">
              <CgSpinner className="w-5 h-5 animate-spin" /> Loading More
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ProjectsScreen;
