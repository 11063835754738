import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const Links = [
    {
      title: "About",
      links: [
        {
          link: "/",
          name: "How It Works",
        },
        {
          link: "/",
          name: "AXYZ Techn",
        },
        {
          link: "/",
          name: "Governance",
        },
        {
          link: "/",
          name: "Token",
        },
      ],
    },
    {
      title: "Privacy",
      links: [
        {
          link: "/",
          name: "Polygon Blockchain",
        },
        {
          link: "/",
          name: "Payment Methods",
        },
        {
          link: "/",
          name: "Return Policy",
        },
      ],
    },
  ];
  return (
    <div className="bg-deepGray rounded-t-3xl py-4">
      <div className="container mx-auto px-12">
        <div className="grid sm:grid-cols-2 md:grid-cols-7 xl:grid-cols-12 gap-5 sm:gap-9 lg:gap-11 xl:gap-7 py-10 justify-between">
          <div className="pb-3.5 sm:pb-0 col-span-1 md:col-span-2 lg:col-span-3">
            <Link to="/">
              <img
                className="w-20 object-contain h-16"
                src="/images/logowhite.png"
                alt="logo"
              />
            </Link>
            <p className="leading-7 font-sans text-sm text-gray-600 mt-3">
              AXYZ META FILM CITY would be a bridge <br /> connecting the world
              of moving pictures, <br /> across various industries
            </p>
          </div>
          {Links.map((l, i) => (
            <div
              key={i}
              className="pb-3.5 sm:pb-0 col-span-1 md:col-span-2 lg:col-span-3"
            >
              <h3 className="text-md lg:leading-7 font-medium mb-4 sm:mb-5 lg:mb-6 pb-0.5">
                {l.title}
              </h3>
              <ul className="text-sm flex flex-col space-y-3">
                {l.links.map((t, index) => (
                  <li className="flex items-baseline" key={index}>
                    <Link
                      to={t.link}
                      className="text-gray-600 inline-block w-full hover:text-subMain"
                    >
                      {t.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {/* contact */}
          <div className="pb-3.5 sm:pb-0 col-span-1 md:col-span-2 lg:col-span-3">
            <h3 className="text-md lg:leading-7 font-medium mb-4 sm:mb-5 lg:mb-6 pb-0.5">
              Contact Us
            </h3>
            <p className="leading-7 font-sans text-sm text-gray-600 mt-3">
              <span>AXYZ META FILM CITY LLC,</span>
              <br />
              <span>HOLLYWOOD 8305 Sunset Blvd,</span>
              <br />
              <span>Los Angeles, California, USA 90069</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
