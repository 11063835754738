import React from "react";

function Head({ project }) {
  return (
    <div className="relative overflow-hidden h-64 md:h-96 bg-deepGray rounded-lg">
      <img
        src={project.image}
        alt="main"
        className="w-full h-full object-cover"
      />
      <div className="absolute flex-colo top-0 left-0 right-0 bottom-0 bg-main bg-opacity-80">
        <h2 className="md:text-4xl text-xl text-center text-white font-bold capitalize">
          {project.title}
        </h2>
      </div>
    </div>
  );
}

export default Head;
