import React from "react";
import { ProjectData } from "../../Data/ProjectData";
import Project from "../Project";
import Titles from "../Titles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

function RecentProject() {
  const pagination = {
    clickable: true,
  };
  return (
    <div className="lg:-mt-52 flex-colo">
      <div className="lg:w-5/6 w-full z-50 bg-main rounded py-6 lg:px-12">
        <Titles
          title="Up Coming"
          subTitle="Project"
          Bg={false}
          link="/projects"
          button={true}
          subt={true}
          buttonText="View All"
        />
        <Swiper
          slidesPerView={1}
          className="mt-6"
          spaceBetween={30}
          pagination={pagination}
          autoplay={true}
          speed={2000}
          loop={true}
          modules={[Pagination, Autoplay]}
          breakpoints={{
            1280: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 2,
            },
          }}
        >
          {ProjectData.map((item, i) => (
            <SwiperSlide key={i} className="pb-20">
              <Project item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default RecentProject;
