import React from "react";
import HowItWork from "../components/HowItWork";
import ProjectDesc from "../components/ProjectDesc";
import Layout from "../layout/Layout";
import { Projects } from "../Data/ProjectData";
import { useParams } from "react-router-dom";

function ProjectViewPage() {
  const { id } = useParams();
  const project = Projects.find((p) => p.id === Number(id));
  return (
    <Layout header={false}>
      <div className="min-h-screen container mx-auto px-2 py-6">
        <div className="flex-colo">
          <div className=" px-12 rounded-2xl bg-deepGray">
            <ProjectDesc project={project} />
          </div>
          <HowItWork project={project} />
        </div>
      </div>
    </Layout>
  );
}

export default ProjectViewPage;
