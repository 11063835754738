import React from "react";
import { Link } from "react-router-dom";

function Project({ item }) {
  return (
    <Link
      to={`/single/${item.id}`}
      className="p-6 hover:scale-95 transitions rounded-2xl bg-deepGray flex-colo gap-8 text-white"
    >
      <div className="w-full h-40 rounded overflow-hidden bg-main">
        <img
          src={item.image}
          alt="imageItem"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex-col flex gap-4 ">
        <h2 className="text-lg font-semibold text-center truncated line-clamp-1">
          {item.title}
        </h2>
        <p className="text-text text-sm">{item.desc.substring(0, 150)}...</p>
      </div>

      <button className="transitions px-8 py-2 flex-colo bg-subMain hover:border-subMain rounded font-bold">
        Read More
      </button>
    </Link>
  );
}

export default Project;
