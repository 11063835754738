import React from "react";

function Banner() {
  return (
    <div className="relative xl:h-header lg:h-header2 h-72 md:h-96  overflow-hidden">
      <video
        controls={false}
        loop
        autoPlay
        muted
        className="w-full h-full object-cover"
      >
        <source src="/images/home/main.mp4" type="video/mp4" />
      </video>
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-overlay z-20">
        <div className="container h-full mx-auto px-2 flex flex-col lg:pt-20 xl:pt-48 justify-center lg:justify-start items-center text-center">
          <h1 className="sm:text-h1 text-2xl sm:leading-normal font-bold text-white">
            Find Your Best Experience <br /> Let's Make It Happen
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Banner;
