import React from "react";
import Layout from "../layout/Layout";
import Summery from "../components/Single/Summery";
import Head from "../components/Single/Head";
import { useParams } from "react-router-dom";
import { ProjectData } from "../Data/ProjectData";

function SingleScreen() {
  const { id } = useParams();
  const project = ProjectData.find((project) => project.id === Number(id));
  const shared = "bg-deepGray rounded-lg p-6 w-full flex flex-col gap-12";
  return (
    <Layout header={false}>
      <div className="min-h-screen container mx-auto px-2 py-2">
        <Head project={project} />
        <div className="flex-colo">
          <div className="xl:w-5/6 flex-colo py-12 gap-20">
            <div className={shared}>
              <Summery project={project} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SingleScreen;
