import React from "react";
import Layout from "../layout/Layout";
import { BiRightArrowAlt } from "react-icons/bi";
import SingleTitle from "../components/Single/SingleTitle";

function MdaoPage() {
  return (
    <Layout header={false}>
      <div className="min-h-screen container mx-auto px-2 py-6 flex-colo">
        <div className="lg:p-20 sm:p-10 py-10 px-6 rounded-xl bg-deepGray ">
          <SingleTitle title="Regional Studios and Studio NFT" />

          <div className="flex-colo  mt-10 xl:gap-20 gap-6 text-text">
            <p>
              Another one of our most market- friendly features is the setting
              up of Filmcity Regional Studios in various cities around the
              world. This would be a well-designed, well-maintained production
              studio with all necessary and modern technology and equipment as
              required. We would be running a blockchain-based poll to get data
              on what types of studios would be the best to be set up in each
              region. This would be depending on a lot of factors
            </p>
            <a
              rel="noreferrer"
              target="_blank"
              href="/"
              className={`flex-rows bg-subMain text-white px-4 md:px-8 hover:scale-105 transitions py-3  gap-4 text-sm rounded-md`}
            >
              View Project <BiRightArrowAlt className="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MdaoPage;
