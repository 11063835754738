import React from "react";

function SingleTitle({ title }) {
  return (
    <div className="bg-main rounded w-full p-6 border border-gray-800">
      <h2 className="md:text-xl text-lg text-white font-bold">{title}</h2>
    </div>
  );
}

export default SingleTitle;
