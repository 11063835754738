import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

function AboutUs() {
  const Class = `bg-main border border-subMain text-white px-4 md:px-8 hover:scale-105 transitions py-3 flex gap-4 text-sm items-center rounded-md`;

  return (
    <div className="md:my-28 my-12">
      <div className=" px-6 sm:px-20 py-10 rounded-xl bg-deepGray text-center flex-colo gap-8">
        <h2 className="text-3xl font-semibold">About Us</h2>
        <p className="text-text text-sm leading-7">
          AXYZ META FILM CITY would be a bridge connecting the world of moving
          pictures, across various industries We are introducing the Filmcity
          Utility Token, FILMY coin is the future of funding, production, and
          networking utilities in the Film and Television industries & Universal
          Film Market (UFM) for Global Networking and Content Production
        </p>
        <Link to="/about_us" className={Class}>
          Read More
          <BiRightArrowAlt className="w-6 h-6" />
        </Link>
      </div>
    </div>
  );
}

export default AboutUs;
