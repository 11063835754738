import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import MobileMenu from "../../components/Drawer/MobileMenu";

const Links = [
  {
    name: "$Film Token",
    link: "/film-token",
  },
  {
    name: "UFM Cart",
    link: "/project/2",
  },
  {
    name: "StarSpace 360",
    link: "/project/3",
  },
  {
    name: "UFD",
    link: "/project/4",
  },
  {
    name: "UFM Trade",
    link: "/project/1",
  },
  {
    name: "MDAO",
    link: "/mdao",
  },
];

const Navbar = ({ header }) => {
  const hover = "hover:text-subMain transitions";
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      {isOpen && <MobileMenu isOpen={isOpen} toggleDrawer={toggleDrawer} />}
      <div
        className={`${
          header ? "bg-white text-main" : "bg-main text-white"
        } shadow-md z-20`}
      >
        <div
          className={`container mx-auto ${
            header ? "lg:py-5 py-3" : "py-8"
          } px-2 gap-10 grid grid-cols-2 lg:grid-cols-7 justify-between items-center`}
        >
          <div className="xl:col-span-1 lg:col-span-2">
            <Link to="/">
              <img
                src={`${header ? "/images/logo.png" : "/images/logo2.png"}`}
                alt="logo"
                className="lg:w-4/6 h-8 w-14 object-contain"
              />
            </Link>
          </div>
          <div className="xl:col-span-6 lg:col-span-5">
            <div className="font-semibold text-sm hidden xl:gap-14 2xl:gap-20 justify-between lg:flex xl:justify-end items-center">
              {Links.map((link, index) => (
                <Link to={link.link} key={index} className={hover}>
                  {link.name}
                </Link>
              ))}
            </div>
            {/* mobile humbuger */}
            <div className="flex text-2xl justify-end items-center lg:hidden">
              <HiMenuAlt3 onClick={toggleDrawer} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Navbar;
