import React from "react";
import SingleTitle from "./SingleTitle";

function Summery({ project }) {
  return (
    <>
      <SingleTitle title={"Project Summary"} />
      <div
        className="md:text-base text-sm leading-7 md:leading-8 text-text"
        dangerouslySetInnerHTML={{ __html: project?.desc }}
      ></div>
    </>
  );
}

export default Summery;
