import React from "react";
import Layout from "../layout/Layout";

const Input = ({ label, options, placeholder, text }) => {
  return (
    <div className="flex flex-col w-full gap-4 text-text">
      <label className="font-medium">{label}</label>
      <div className="w-full grid grid-cols-3 lg:grid-cols-12 gap-2 border border-text rounded bg-main">
        <input
          type="number"
          className="lg:col-span-10 col-span-2 border-0 bg-transparent p-4 "
          placeholder={placeholder}
        />
        <select className="lg:col-span-2 bg-transparent border-0 p-4">
          {options.map((o, i) => (
            <option className="text-main" key={i}>
              {o}
            </option>
          ))}
        </select>
      </div>
      <p className="text-white text-xs">{text}</p>
    </div>
  );
};

function BuyToken() {
  return (
    <Layout header={false}>
      <div className="min-h-screen text-center flex-colo gap-12 container mx-auto px-2 py-2">
        <h2 className="text-3xl text-white font-serif font-bold mt-3">
          Buy "$Film" Token
        </h2>
        <div className="xl:w-2/4 md:w-3/4 w-full bg-deepGray flex-colo gap-12 rounded p-6 md:p-8">
          <Input
            label={"Exchange Crypto"}
            options={["ETH", "USDT", "USDC", "BNB", "BUSD"]}
            placeholder={0.0}
            text="-$56.90"
          />
          <Input
            label="$Film Token"
            options={["Film"]}
            placeholder={0.985}
            text="$676.90"
          />
          <button className="w-full hover:bg-main border-2 border-subMain transitions bg-subMain text-white py-4 font-semibold rounded">
            Buy Token
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default BuyToken;
