import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

function Titles({
  title,
  subt,
  newTab,
  subTitle,
  buttonText,
  Bg,
  link,
  button,
}) {
  const Class = `${
    Bg ? "bg-subMain text-white" : "text-white"
  } px-4 md:px-8 hover:scale-105 transitions py-3 flex gap-4 text-sm items-center rounded-md`;

  return (
    <div className="w-full flex-wrap flex gap-8 items-center justify-between">
      <div>
        {subt && (
          <div className="flex gap-4 items-center">
            <p className="uppercase tracking-widest text-sm text-subMain font-semibold">
              {subTitle}
            </p>
            <hr className="w-12 border-2 border-subMain" />
          </div>
        )}

        <h2
          className={`${
            subt ? "text-2xl" : "text-4xl"
          }  text-white font-bold mt-3`}
        >
          {title}
        </h2>
      </div>
      {button && (
        <div>
          {newTab ? (
            <a target="_blank" rel="noreferrer" href={link} className={Class}>
              {buttonText}
              <BiRightArrowAlt className="w-6 h-6" />
            </a>
          ) : (
            <Link to={link} className={Class}>
              {buttonText}
              <BiRightArrowAlt className="w-6 h-6" />
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default Titles;
