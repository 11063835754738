import React from "react";
import AboutUs from "../components/Home/AboutUs";
import Banner from "../components/Home/Banner";
import RecentProject from "../components/Home/RecentProject";
import Layout from "../layout/Layout";
import HomeProjects from "../components/Home/HomeProjects";

function HomeScreen() {
  return (
    <Layout header={true}>
      <Banner />
      <div className="min-h-screen container mx-auto px-2 my-6">
        <RecentProject />
        <div className="flex-colo">
          <div className="lg:w-5/6 w-full">
            <AboutUs />
            <HomeProjects />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HomeScreen;
