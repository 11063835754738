import { BiWorld } from "react-icons/bi";
import { FaDiscord, FaSearchDollar } from "react-icons/fa";
import { HiOutlineFilm, HiFire } from "react-icons/hi";
import { RiLayoutGridFill, RiMoneyDollarCircleFill } from "react-icons/ri";
import { IoIosChatboxes } from "react-icons/io";
import { BsFillPatchCheckFill } from "react-icons/bs";

export const ProjectData = [
  {
    id: 1,
    image: "/images/thumb1.png",
    title: "Meta Star Arcade",
    desc: "This would be an exhibition space for stars from various industries to display their achievements, journey in the film world and major milestones. A museum of celebrities that can be accessed by enthusiasts from all across the world. Meta Star Arcade would be a great way to promote and celebrate one’s presence in the film world. This would be the world’s first exhibition of its kind. Metaverse exhibition and Interactive platform, accessible to all, including the filmmaking community and the audiences We are designing and developing one of the most attractive features of our project, the Metaverse Exhibition Platform from Filmcity. This can be accessed by members of the global filmmaking community as well as film enthusiastic audiences who are deeply connected to the art of cinema. Filmcity Metaverse Exhibition platform has several features like virtual studio visits, direct interaction with professionals, etc. Several film studios around the world have stunning architecture and would be filled with various exciting spaces. While visiting these properties virtually through our metaverse platform, people can see and understand the various processes involved in filmmaking and content crafting. Most film studios would also have museum-like spaces, exhibiting historic pieces and information related to the various industries around the world. VirtualExperience theatres would be showcasing the journey of the industries.",
  },
  {
    id: 2,
    image: "/images/thumb2.png",
    title: " Metaverse Film Awards",
    desc: "This would be a blockchain-based project, with eminent panel members creating the list of movies, short movies, documentaries or other content and artists, who are eligible for awards. These lists would then be set for voting through the blockchain. Thus, we would have the best film, director, actors, cinematographers, editors, singers and several other honours being selected through a transparent and unique mode of selection. It would be a completely new concept, wherein the public gets to cast their votes. The ceremony would be huge and can be experienced both online and offline.",
  },
  {
    id: 3,
    image: "/images/thumb3.png",
    title: "UFM Members Club",
    desc: "This would be a universal digital club, connecting all members of the global film community. Bridging the gap between technicians, artists, makers, producers, distributors, etc and the gap between prominent film industries and those with less or nill visibility. We are building a global community to bring together makers, technicians and other film personnel from various parts of the world. It helps in the collaboration and mutual support of artists from different regions. This can also be used to promote movies across the world. To hold the UFM NFT, one should submit details for involvement in the Film-Television industries around the world. Even if they have been part of only a single project, they would be eligible to possess the UFM NFT. The UFM NFT would be released by Filmcity for building a community among global filmmakers, technicians and artists. FILMLOUNGE- Utility of UFM NFT: Though we have access to several different social media platforms, to be able to sign up to one exclusively for the film-television community from various parts of the world is quite iconic. It can offer more freedom and accessibility for members to connect, collaborate and share with each other. This can boost the growth of networking in our industries. Members of the UFM club would be the ones who can sign into the FILMLOUNGE. This platform would only be carrying news and updates on the Film and other entertainment industries. Film personnel from all corners of the world can communicate with others in the field and share their content for better visibility in the community. It can be effectively used to develop teams and projects with technicians and artists from around the globe. Special group chats, Discussion forums, etc can be set up on this platform, for more serious talks and presentations.",
  },
  {
    id: 4,
    image: "/images/thumb4.png",
    title: "META TV 360",
    desc: "A television channel from Axyz Meta Filmcity, which would be exclusively dedicated to news and content from the entertainment industry/. Filmstars, technicians and other celebrities can interact and become part of the programmes, through their Avatar counterparts on the Metaverse platform. Updates and breaking news from the industry would reach the public easily through Meta TV 360. Live and recorded segments would be broadcasted from Meta TV 360.",
  },
];

export const Projects = [
  {
    id: 1,
    title: "UFM Trade",
    dec: " Each NFT would be having unique information stored within them.",
    image: "/images/home/p1.jpg",
    link: "https://minvest-dapp.vercel.app/",
    features: [
      {
        title: "Access the world ",
        bg: false,
        icon: BiWorld,
        dec: " The Studio NFT from Filmcity would be released for setting up regional studios with the best facilities available at the most popular studios in the world. ",
      },
      {
        title: "M- DAO",
        bg: true,
        icon: RiLayoutGridFill,
        dec: " The NFT holders thus become part of a DAO or Decentralized Autonomous Organization.",
      },
      {
        title: "The Studio NFT",
        bg: false,
        icon: IoIosChatboxes,
        dec: "This can also be considered a Governance NFT, giving voting power to its owners. There would be conditions applied to this feature. A person with one NFT will not be given this power.",
      },
    ],
    howItWork: [
      {
        image: "/images/home/4.png",
        dec: "View the project and connect a crypto wallet using metamask or wallet connect, make sure you are connected properly.",
      },
      {
        image: "/images/home/5.png",
        dec: "Purchase/ exchange sufficient $Film token",
      },
      {
        image: "/images/home/6.png",
        dec: "View, search and purchase NFT’s fractions to represent investment for movie production",
      },
    ],
  },
  {
    id: 2,
    title: "Universal Film market",
    dec: "E-commerce Platform For Film-related Products",
    image: "/images/home/p3.jpg",
    link: "https://ufm-commerce.vercel.app/",
    features: [
      {
        title: "Shop for movie themed items ",
        bg: false,
        icon: FaSearchDollar,
        dec: "This would be separately designed and developed for selling and purchasing all types of products required for a film, from its inception to the final exhibition. ",
      },
      {
        title: "Get unique blockbuster merchandise",
        bg: true,
        icon: BsFillPatchCheckFill,
        dec: "Extending from products like make up and costumes to other technical gear like cameras, lenses, lights, etc. ",
      },
      {
        title: "Shop movie pro equipments",
        bg: false,
        icon: RiMoneyDollarCircleFill,
        dec: "Purchase may be done through crypto or credit cards according to the preference of the buyer. Once a product from the USA is purchased by someone in India, it would be shipped through blockchain logistics, for easier and quicker delivery as required.",
      },
    ],
    howItWork: [
      {
        image: "/images/home/7.png",
        dec: "Visit the Ecommerce platform and make purchases.",
      },
      {
        image: "/images/home/8.png",
        dec: "Connect wallet to earn $Film token for every purchase",
      },
      {
        image: "/images/home/9.png",
        dec: "Sign up as a vendor and sell your movie products for free",
      },
    ],
  },
  {
    id: 3,
    title: "StarSpace 360",
    dec: "Reach award winning celebrities and contact them directly",
    image: "/images/home/p4.png",
    link: "https://starspace360.axyzufm.com",
    features: [
      {
        title: "Find professional service providers ",
        bg: false,
        icon: FaDiscord,
        dec: "Stars and the production teams can interact with each other and discuss the projects and the artist can decide if they wish to join the project or not.",
      },
      {
        title: "List the service you offer in the industy ",
        bg: true,
        icon: HiOutlineFilm,
        dec: "Through Star Space 360, we aim to bridge this gap. Stars would have the option to interact with authentic production houses and their team or they would remain within the reach",
      },
      {
        title: "Pay with $Film token",
        bg: false,
        icon: HiFire,
        dec: "Even the production houses that have the potential and backing to make huge movies remain disconnected from these stars.",
      },
    ],
    howItWork: [
      {
        image: "/images/home/1.png",
        dec: "Apply for access to the star space platform using your corporate KYC after purchasing sufficient $Film token",
      },
      {
        image: "/images/home/2.png",
        dec: "Communicate with celebrities directly",
      },
      {
        image: "/images/home/3.png",
        dec: "Get contracts going with ease",
      },
    ],
  },
  {
    id: 4,
    title: "Universal Film Directory",
    dec: "Global Employment Platform For Technicians And Artists",
    image: "/images/about3.png",
    link: "https://ufd.axyzufm.com/",
    features: [
      {
        title: "Find professional service providers ",
        bg: false,
        icon: FaDiscord,
        dec: "Several talented/ experienced technicians and artists from various regional industries remain in the shadows, without ample opportunities and visibility.",
      },
      {
        title: "List the service you offer in the industy ",
        bg: true,
        icon: HiOutlineFilm,
        dec: "Professionals like junior artists would normally be signed under a certain agency. This would cause them to lose a huge portion of their remuneration to the middleman/ agency.",
      },
      {
        title: "Pay with $Film token",
        bg: false,
        icon: HiFire,
        dec: "UFD also opens a large pool of contacts for production houses or production teams to find excellent technicians to join their projects.",
      },
    ],
    howItWork: [
      {
        image: "/images/home/4.png",
        dec: "Sign up as a freelancer and list your services or search for freelancers",
      },
      {
        image: "/images/home/2.png",
        dec: "Chat with freelancers and hire freelancers easily",
      },
      {
        image: "/images/home/5.png",
        dec: "Get your tasks done and make payment with ease",
      },
    ],
  },
];

export const HomeProjectsData = [
  {
    link: "/project/1",
    image: "/images/home/p1.jpg",
    title: "UFM Trade",
  },
  {
    link: "/project/2",
    image: "/images/home/p3.jpg",
    title: "UFM Cart",
  },
  {
    link: "/project/4",
    image: "/images/home/p2.png",
    title: "Universal Film Directory",
  },
  {
    link: "/project/3",
    image: "/images/home/p4.png",
    title: "StarSpace 360",
  },
  {
    link: "/film-token",
    image: "/images/home/film.png",
    title: "$Film Token",
  },
  {
    link: "/mdao",
    image: "/images/home/dao.png",
    title: "MDAO",
  },
];
