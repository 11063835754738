import React from "react";
import HomeScreen from "./screens/HomeScreen";
import { Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./ScrollOnTop";
import NotFound from "./screens/NotFound";
import ProjectsScreen from "./screens/ProjectsPage";
import SingleScreen from "./screens/SinglePage";
import BuyToken from "./screens/BuyToken";
import AboutScreen from "./screens/AboutUsPage";
import ProjectViewPage from "./screens/ProjectsViewPage";
import FilmTokenPage from "./screens/FilmTokenPage";
import MdaoPage from "./screens/MdaoPage";

function App() {
  AOS.init();
  return (
    <ScrollToTop>
      <Routes>
        <Route exact path="/" element={<HomeScreen />} />
        <Route exact path="/about_us" element={<AboutScreen />} />
        <Route exact path="/projects" element={<ProjectsScreen />} />
        <Route exact path="/single/:id" element={<SingleScreen />} />
        <Route exact path="/buytoken" element={<BuyToken />} />
        <Route exact path="/project/:id" element={<ProjectViewPage />} />
        <Route exact path="/film-token" element={<FilmTokenPage />} />
        <Route exact path="/mdao" element={<MdaoPage />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  );
}

export default App;
