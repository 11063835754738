import React from "react";
import Titles from "./Titles";

function ProjectDesc({ project }) {
  return (
    <div className="w-full my-20">
      <Titles
        title={project.title}
        Bg={true}
        link={project.link}
        button={false}
        subt={false}
      />
      <p className="my-6 font-semibold leading-7 xl:w-2/4 text-text">
        {project.dec}
      </p>
      <div className="xl:grid flex-colo grid-cols-12 gap-12 ">
        <div className="flex-colo gap-12 col-span-7 text-center md:text-start mt-6">
          {project.features.map((f, index) => (
            <div
              key={index}
              className="md:grid flex-colo items-center grid-cols-12 gap-6 w-full"
            >
              <div
                className={`col-span-2 md:w-auto w-20 h-20 transitions ${
                  f.bg ? "bg-subMain" : "bg-main hover:text-subMain"
                }  rounded-xl flex-colo text-2xl`}
              >
                <f.icon />
              </div>
              <div className="col-span-10">
                <h2 className="text-lg font-semibold">{f.title}</h2>
                <p className="mt-3 leading-6 text-sm text-text">{f.dec}</p>
              </div>
            </div>
          ))}
        </div>
        <div
          className="col-span-5 h-full"
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-delay="10"
          data-aos-duration="1000"
        >
          <img
            src={project.image}
            alt={project.title}
            className="w-full hover:-translate-y-6 transitions h-full rounded-md object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectDesc;
