import React from "react";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import MainDrawer from "./MainDrawer";

const Links = [
  {
    name: "$Film Token",
    link: "/film-token",
  },
  {
    name: "UFM Cart",
    link: "/project/2",
  },
  {
    name: "StarSpace 360",
    link: "/project/3",
  },
  {
    name: "UFD",
    link: "/project/4",
  },
  {
    name: "UFM Trade",
    link: "/project/1",
  },
  {
    name: "MDAO",
    link: "/mdao",
  },
];

function MobileMenu({ isOpen, toggleDrawer }) {
  const hover =
    " py-4 px-10 border-b hover:bg-subMain border-subMain w-full text-white transitions";
  return (
    <>
      <MainDrawer isOpen={isOpen} toggleDrawer={toggleDrawer}>
        <div className="bg-main py-4 w-full h-screen text-white">
          <Link to="/" onClick={toggleDrawer}>
            <img
              src={"/images/logo2.png"}
              alt="logo"
              className="w-full h-6 object-contain"
            />
          </Link>
          <div className="flex-colo mt-4">
            {Links.map((link, index) => (
              <Link
                onClick={toggleDrawer}
                key={index}
                className={hover}
                to={link.link}
              >
                {link.name}
              </Link>
            ))}
          </div>
        </div>
      </MainDrawer>
    </>
  );
}

export default MobileMenu;
